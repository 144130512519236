

import Navigat from './Navigat';
function App() {
  return (
    <div >
       <Navigat/> 
 
    </div>
  );
}

export default App;
